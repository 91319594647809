'use strict';
(function () {
  var _controller = 'chatMessage.component.controller'
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('chatMessage', {
      template: '<ng-include src="templateUrl" onload="templateInit()"></ng-include>',
      controller: _controller,
      bindings: {
        chatEntry: '=',
        showMessagePanel: '=',
        chatResource: '<'
      }
    })

  controller.$inject = ['$scope', '$element', 'httpInterceptor'];
  function controller($scope, $element, httpInterceptor) {
    $scope.templateUrl = $scope.$root.employeeApp ? '/frontend/commons/components/chat/messages/chatMessage.component.html' : 'components/chat/messages/chatMessage.component.jade';
    // METHODS
    $scope.resend = resend;
    $scope.getStyles = getStyles;
    $scope.downloadAs = downloadAs;
    // $scope.getFileSize = getFileSize;
    $scope.selectMessage = selectMessage;
    $scope.handleError = handleError;

    // VARS
    var that = this;
    var $ctrl = this;

    var httpRegex = /(?:http:\/\/)|(?:https:\/\/)/gm;
    var regexHasUrl = /((?:http:\/\/)|(?:https:\/\/))|(www.)?((?:[a-zA-Z0-9]+\.[a-z]{3})|(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?::\d+)?))([\/a-zA-Z0-9\.]*)/gm;
    var regexIsUrl = /^((?:http:\/\/)|(?:https:\/\/))|(www.)?((?:[a-zA-Z0-9]+\.[a-z]{3})|(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?::\d+)?))([\/a-zA-Z0-9\.]*)$/gm;
    this.whereIAm = $scope.$root.employeeApp ? 'MOBILE' : 'PWA';
    $scope.profile = profile;

    // WATCHERS
    $scope.$on('$destroy', onDestroy);

    // INIT

    /// On component init
    this.$onInit = function () {
      if (this.chatEntry && !this.chatEntry.files) checkMessage(this.chatEntry)
      if (this.chatEntry?.files?.length || this.chatEntry?.crc) {
        $ctrl.filename = chatFunctions.getFileName(this.chatEntry, this.chatEntry.type)
        $ctrl.filesize = getFileSize(this.chatEntry)
      }
    };

    /////////////////////

    function selectMessage(chatEntry, $event) {
      if (!chatEntry) return
      if (that.showMessagePanel && typeof that.showMessagePanel === 'function') that.showMessagePanel(chatEntry, $event);
    }

    function getStyles(chatEntry, type) {
      return chatFunctions.getStyles(chatEntry, type, that.whereIAm);
    }

    function getFileSize(chatEntry) {
      if (!chatEntry) return '0'
      if (chatEntry.files?.length) return chatFunctions.getFileSize(chatEntry.files[0]); //retro compatibilidad con pmxFs
      else return chatFunctions.getFileSize(chatEntry)
    }

    function checkMessage(chatEntry) {
      if (!chatEntry.message) return
      if (!hasUrl(chatEntry.message)) return //- Verifico que el mensaje contenga un url.
      var splitMessages = chatEntry.message.split(" ");
      if (!splitMessages || !splitMessages.length) return
      var newMessage = '';
      splitMessages.forEach(function (message) {
        var str = isUrl(message); //- Verifico que el string es un url.
        if (!str) newMessage += message + ' ';
        else {
          if (hasHttp(str)) newMessage += '<a href="http://' + message + '" target="_blank" rel="noopener noreferrer">' + message + '</a> ';
          else newMessage += '<a href="' + message + '" target="_blank" rel="noopener noreferrer">' + message + '</a> ';
        }
      })
      chatEntry._htmlMessage = newMessage;
    }

    function downloadAs(item, resource) {
      ons.notification.toast(i18next.t('Su archivo se está descargando, por favor espere...'), { timeout: 2000 })
      chatFunctions.downloadAs(item, resource)
    }

    function hasUrl(message) { return regexHasUrl && regexHasUrl.exec(message) } //- Verifico que todo el mensaje contenga un url.

    function isUrl(message) { return regexIsUrl && regexIsUrl.exec(message) } //- Verifico que el string analizado sea un url.

    function hasHttp(arr) { //- Verifico que el array contenga http:// o https:// para luego agregarlo si este no esta.
      if (!arr || !arr.length) return false
      var exist = arr.find(function (f) { return httpRegex.exec(f) });
      if (exist) return false
      return true
    }

    function resend(chatEntry) { $scope.$root.chatControl.resendMessage(chatEntry) }

    //- Esta function sirve para manejar errores especificos
    function handleError(err) {
      if (!err) return i18next.t('Ocurrió un error al enviar mensaje')
      switch (err) {
        case 'ERR-MESSAGE-LIMIT-EXCEEDED': return i18next.t('No hay suficientes créditos para enviar un mensaje por WhatsApp');
        default: return i18next.t('Ocurrió un error al enviar mensaje')
      }
    }
    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
    //// FIN CTRL
  }

})();
